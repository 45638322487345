import React from 'react';
import { createUseStyles } from 'react-jss';
import backgroundImage from '../assets/shmp.png'; // Replace with an appropriate image
import logo1 from '../assets/tdis-logo.png';
import logo2 from '../assets/idrt-logo.jpg'; // Make sure this logo has a transparent background
import logo3 from '../assets/university-logo-red.png';
import TDEM_LOGO from '../assets/TDEM_logo.png';

const useStyles = createUseStyles({
    '@global': {
        body: {
            fontFamily: '"OpenSans Condensed Regular", sans-serif',
            margin: 0,
            overflowX: 'hidden',
            backgroundColor: '#F0F3F4' // Using "Ice" from the color palette
        }
    },
    splitLayout: {
        display: 'flex',
        height: '100vh',
        alignItems: 'stretch'
    },
    imageSide: {
        flex: 1.5,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat'
    },
    loginSide: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', // Center the content vertically
        alignItems: 'center',
        backgroundColor: '#FFFFFF', // Use "Snow" from the color palette
        padding: '3rem 2rem',
        position: 'relative' // To position the background logo absolutely
    },
    logoHeader: {
        alignSelf: 'stretch',
        display: 'flex',
        justifyContent: 'space-evenly',
        marginBottom: '3rem' // More space from logos to the login card
    },
    logo: {
        height: '50px',
        objectFit: 'contain'
    },
    loginCard: {
        backgroundColor: '#F0F3F4', // Using "Sage" for the login card
        padding: '3rem', // Increased padding for a larger card
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        width: '80%', // Increased width
        maxWidth: '500px', // Increased max width
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        top: '30px' // Adjust if necessary
    },
    loginButton: {
        fontFamily: '"OpenSans Condensed Bold", sans-serif',
        fontSize: '1.25rem',
        backgroundColor: '#500000', // Using "Whoop" for the button
        color: '#FFFFFF',
        padding: '15px 30px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#1B1C20' // Using "Rain" for the hover effect
        },
        marginTop: '2rem',
        marginBottom: '2rem' // Adds margin below the button
    },
    backgroundLogo: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundImage: `url(${TDEM_LOGO})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        opacity: 0.025, // Adjust opacity to make the logo barely visible
        zIndex: -1.1 // Ensure it's behind all other content
    }
});

function LoginComponent({ onLogin }) {
    const classes = useStyles();

    return (
        <div className={classes.splitLayout}>
            <div className={classes.imageSide}></div>
            <div className={classes.loginSide}>
                <div className={classes.backgroundLogo} />
                <div className={classes.logoHeader}>
                    <img src={logo1} alt='TDIS Logo' className={classes.logo} />
                    <img src={logo3} alt='TDEM Logo' className={classes.logo} />
                    <img src={logo2} alt='IDRT Logo' className={classes.logo} />
                </div>
                <div className={classes.loginCard}>
                    <button className={classes.loginButton} onClick={onLogin}>
                        EXPLORE SHMP DATA
                    </button>
                </div>
            </div>
        </div>
    );
}

export default LoginComponent;
