import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PrivateSection from 'routes/PrivateSection';
import PublicRoutes from 'routes/PublicRoutes';
import LoginComponent from 'routes/LoginComponent'; // Assume this is the component you show initially

function Routes() {
    const { pathname } = useLocation();
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // Handler to simulate user login
    const handleLogin = () => {
        setIsUserLoggedIn(true);
    };

    // Initially show LoginComponent if not logged in
    if (!isUserLoggedIn) {
        return <LoginComponent onLogin={handleLogin} />;
    }

    // After login, decide which section to show
    return isUserLoggedIn ? <PrivateSection /> : <PublicRoutes />;
}

export default Routes;
