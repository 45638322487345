export default {
    // private
    overview: '/overview',
    search: '/search',
    advancedsearch: '/advancedsearch',
    reports: '/reports',
    mappings: '/mappings',
    rankings: '/rankings',
    regions: '/regions',
    settings: '/settings',
    subscription: '/subscription',
    // auth
    login: '/login',
    signup: '/signup',
    forgotPassword: '/forgot_password'
};
