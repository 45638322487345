import React from 'react';
import { Row } from 'simple-flexbox';
import { createUseStyles, useTheme } from 'react-jss';
import logoImage from 'assets/university-logo.png'; // Make sure the path is correct

const useStyles = createUseStyles((theme) => ({
    container: {
        marginLeft: 32,
        marginRight: 32
    },
    logo: {
        width: '32px', // Set the width of the logo
        height: '32px' // Set the height of the logo, you can adjust the size as needed
    },
    title: {
        ...theme.typography.cardTitle,
        color: '#FFFFFF',
        opacity: 0.9,
        marginLeft: 12
    }
}));

function LogoComponent() {
    const theme = useTheme();
    const classes = useStyles({ theme });
    return (
        <Row className={classes.container} horizontal='center' vertical='center'>
            <span className={classes.title}>State Hazard Mitigation Plan Dataset</span>
        </Row>
    );
}

export default LogoComponent;
