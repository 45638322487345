import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import LoadingComponent from 'components/loading';

const OverviewComponent = lazy(() => import('./overview'));
const SearchComponent = lazy(() => import('./search'));
const AdvancedSearchComponent = lazy(() => import('./advancedsearch'));
const RankingsComponent = lazy(() => import('./rankings'));
const ReportsComponent = lazy(() => import('./reports'));
const RegionsComponent = lazy(() => import('./regions'));
const MappingsComponent = lazy(() => import('./mappings'));
const LoginComponent = lazy(() => import('./LoginComponent'));

function PrivateRoutes() {
    return (
        <Suspense fallback={<LoadingComponent loading />}>
            <Switch>
                <Route exact path={SLUGS.login} component={LoginComponent} />
                <Route exact path={SLUGS.overview} component={OverviewComponent} />
                <Route exact path={SLUGS.search} component={SearchComponent} />
                <Route exact path={SLUGS.advancedsearch} component={AdvancedSearchComponent} />
                <Route exact path={SLUGS.rankings} component={RankingsComponent} />
                <Route exact path={SLUGS.regions} component={RegionsComponent} />
                <Route exact path={SLUGS.reports} component={ReportsComponent} />
                <Route exact path={SLUGS.mappings} component={MappingsComponent} />
                <Redirect to={SLUGS.overview} />
            </Switch>
        </Suspense>
    );
}

export default PrivateRoutes;
