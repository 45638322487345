import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import {
    IconContacts,
    IconIdeas,
    IconSettings,
    IconOverview,
    IconSearch,
    IconSubscription
} from 'assets/icons';
import { convertSlugToUrl } from 'resources/utilities';
import LogoComponent from './LogoComponent';
import Menu from './MenuComponent';
import MenuItem from './MenuItemComponent';
import logo2 from '../../assets/university-logo.png'; // Logo import

const useStyles = createUseStyles({
    menu: {
        position: 'relative', // Crucial for the sidebarFooter's absolute positioning
        height: '100%', // Ensure the menu fills its container or viewport
        display: 'flex',
        flexDirection: 'column'
    },
    separator: {
        borderTop: ({ theme }) => `1px solid ${theme.color.tdisSnow}`,
        marginTop: 16,
        marginBottom: 16,
        opacity: 0.06
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0'
    },
    supportLink: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        color: 'white',
        padding: '10px 20px',
        fontSize: '16px',
        '& a': {
            textDecoration: 'none',
            color: 'white',
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    },
    sidebarFooter: {
        marginTop: 'auto', // Pushes to the bottom
        width: '100%'
    }
});

function SidebarComponent() {
    const { push } = useHistory();
    const theme = useTheme();
    const classes = useStyles({ theme });
    const isMobile = window.innerWidth <= 1080;

    async function logout() {
        push(SLUGS.login);
    }

    function onClick(slug, parameters = {}) {
        push(convertSlugToUrl(slug, parameters));
    }

    return (
        <Menu isMobile={isMobile}>
            <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                <LogoComponent />
            </div>
            <MenuItem
                id={SLUGS.overview}
                title='Overview'
                icon={IconOverview}
                onClick={() => onClick(SLUGS.overview)}
            />
            <MenuItem
                id={SLUGS.reports}
                title='Hazard Events and Damage'
                icon={IconSubscription}
                onClick={() => onClick(SLUGS.reports)}
            />
            <MenuItem
                id={SLUGS.advancedsearch}
                title='
                Search'
                icon={IconSettings}
                onClick={() => onClick(SLUGS.advancedsearch)}
            />
            <MenuItem
                id={SLUGS.rankings}
                title='County Rankings'
                icon={IconContacts}
                onClick={() => onClick(SLUGS.rankings)}
            />
            <MenuItem
                id={SLUGS.regions}
                title='TDEM Regions'
                icon={IconIdeas}
                onClick={() => onClick(SLUGS.regions)}
            />
            <div className={classes.separator}></div>
            <div className={classes.logoContainer}>
                <img src={logo2} alt='University Logo' />
            </div>
            <div className={classes.separator}></div>
            <a href='mailto:tdis@tamu.edu' className={classes.supportLink}>
                TDIS Support
            </a>
        </Menu>
    );
}

export default SidebarComponent;
