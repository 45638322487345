import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Row } from 'simple-flexbox';
import { createUseStyles, useTheme } from 'react-jss';
import { SidebarContext } from 'hooks/useSidebar';

// Import the logos
import logo1 from '../../assets/tdis-logo.png';
import logo2 from '../../assets/idrt-logo.jpg';
import TDEM_logo from '../../assets/TDEM_logo.png'; // Assuming another logo is added
// Assuming you have a generic avatar icon in your assets

const useStyles = createUseStyles((theme) => ({
    avatar: {
        height: 35,
        width: 35,
        borderRadius: '50%',
        border: `2px solid ${theme.color.lightGrayishBlue2}`,
        marginLeft: 10,
        cursor: 'pointer',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
            transform: 'scale(1.1)'
        }
    },
    container: {
        // Other container styles remain the same
        justifyContent: 'space-between' // Adjust based on logo positioning preference
    },
    logosContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', // Center logos. Change to 'flex-end' for right-hand side positioning.
        flexGrow: 1,
        opacity: 0.5, // Make logos barely visible
        transition: 'opacity 0.5s ease-in-out',
        '&:hover': {
            opacity: 0.8 // Increase opacity on hover for better visibility
        }
    },
    logo: {
        // Adjust logo styles if needed, especially size for a centered/right-hand side design
        height: 50, // Example adjustment for logo size
        marginRight: 10 // Adjust based on visual balance needed
    },
    userSection: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative'
    },
    separator: {
        borderLeft: `1px solid ${theme.color.lightGrayishBlue2}`,
        margin: '0 32px',
        height: 32
    }
}));

function HeaderComponent() {
    const history = useHistory();
    const { currentItem } = useContext(SidebarContext);
    const theme = useTheme();
    const classes = useStyles({ theme });

    const handleLogout = () => {
        console.log('Logging out...');
        // Implement logout functionality here
    };

    return (
        <Row className={classes.container} vertical='center'>
            {/* Other elements remain the same */}
            <div className={classes.logosContainer}>
                <img src={logo1} alt='Logo 1' className={classes.logo} />
                <img src={TDEM_logo} alt='TDEM Logo' className={classes.logo} />
                <img src={logo2} alt='Logo 2' className={classes.logo} />
            </div>
            {/* User section or other components */}
        </Row>
    );
}

export default HeaderComponent;
